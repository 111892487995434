import React from 'react'
import { graphql } from 'gatsby'
import { Alert } from 'reactstrap'
import axios from 'axios'
import IconSocial from '../components/iconsocial'
import Layout from '../components/layout'
import InsideHeader from '../components/insideheader'
import Metatags from '../components/Metatags'
import ReactMarkdown from 'react-markdown'
import 'react-dates/initialize';
import { ScrollableOrientationShape, DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

class BooknowTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      props: props,
      page: props.data.dataJson,
      name: '',
      email: '',
      mobile: '',
      checkin: '',
      checkout: '',
      startDate: null,
      endDate: null,
      message: '',
      alertVisible: false,
      alertMessage: '',
      alertColor: 'success',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onDismiss = this.onDismiss.bind(this)
  }

  handleChange(event) {
    var formvar = {}
    formvar[event.target.name] = event.target.value
    this.setState(formvar)
  }

  onDismiss() {
    this.setState({ alertVisible: false, alertMessage: '' })
  }

  handleSubmit(event) {
    var formData = new FormData()
    const that = this
    formData.set('name', this.state.name)
    formData.set('email', this.state.email)
    formData.set('mobile', this.state.mobile)
    formData.set('startDate', moment(this.state.startDate).format('LL'))
    formData.set('endDate', moment(this.state.endDate).format('LL'))
    formData.set('message', this.state.message)
    axios
      .post('https://www.armandelhi.com/processformbooknow.php', formData, {
        header: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      })
      .then(function(response) {
        console.log(response)
        if (response.data.hasOwnProperty('success')) {
          that.setState({
            alertVisible: true,
            alertColor: 'success',
            alertMessage: response.data.success,
            name: '',
            email: '',
            mobile: '',
            checkin: '',
            checkout: '',
            message: '',
          })
        } else if (response.data.hasOwnProperty('error')) {
          that.setState({
            alertVisible: true,
            alertColor: 'error',
            alertMessage: response.data.error,
          })
        }
      })
      .catch(function(error) {
        console.log(error)
        that.setState({
          alertVisible: true,
          alertColor: 'error',
          alertMessage: error,
        })
      })
    event.preventDefault()
  }

  render() {
    return (
      <Layout>
      <Metatags
               title={this.state.page.meta.metatitle}
               keywords={this.state.page.meta.metakeywords}
               description={this.state.page.meta.metadescription}
               thumbnail={this.state.page.meta.metaimage}
               url={this.state.page.meta.metaurl}
               pathname={this.state.page.meta.metapathname}
           />
        <InsideHeader
                pageTitle={this.state.page.title}
                pageSubTitle={this.state.page.subTitle}
                pageBackground={this.state.page.titleBackground}
              />
        <main className="main-content">
          <section className="section">
            <div className="container">
              <div className="row">
                <div className="col-md-11 mx-auto">
                  <div className="container">
                    <form
                      name="sendQuery"
                      className="form-row input-border needs-validation"
                      noValidate={true}
                      action="#"
                      onSubmit={this.handleSubmit}
                    >
                      <div className="col-lg-12">
                        <h3>Booking Request</h3>
                        <br />
                        <div className="col-12">
                          <Alert
                            color={this.state.alertColor}
                            isOpen={this.state.alertVisible}
                            toggle={this.onDismiss}
                          >
                            {this.state.alertMessage}
                          </Alert>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-12">
                            <input
                              className="form-control form-control-lg shadow"
                              type="text"
                              name="name"
                              placeholder="Name"
                              onChange={this.handleChange}
                              required={true}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <input
                              className="form-control form-control-lg shadow"
                              type="email"
                              name="email"
                              placeholder="Email"
                              onChange={this.handleChange}
                              required={true}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <input
                              className="form-control form-control-lg shadow"
                              type="text"
                              name="mobile"
                              placeholder="Mobile"
                              onChange={this.handleChange}
                              required={true}
                            />
                          </div>

                          <div className="form-group col-md-6">
                          <DateRangePicker
                              startDateId="startDate"
                              endDateId="endDate"
                              startDatePlaceholderText="Check in"
                              endDatePlaceholderText="Check Out"
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              onDatesChange={({ startDate, endDate }) => { this.setState({ startDate: startDate, endDate: endDate })}}
                              focusedInput={this.state.focusedInput}
                              onFocusChange={(focusedInput) => { this.setState({ focusedInput })}}
                            />
                          </div>

                        </div>


                        <div className="form-group">
                          <textarea
                            className="form-control form-control-lg shadow"
                            rows="4"
                            placeholder="Message"
                            name="message"
                            onChange={this.handleChange}
                            required={true}
                          />
                        </div>

                        <button
                          className="btn btn-lg btn-primary shadow"
                          type="submit"
                        >
                          Send message
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export const query = graphql`
  query($slug: String!) {
    dataJson(fields: { slug: { eq: $slug } }) {
      title
      subTitle
      titleBackground
      contents {
        heading
        image
        content
        heading
      }
      meta {
          metadescription
      metakeywords
      metatitle
      metaimage
      metaurl
      metapathname
      }
    }
  }
`

export default BooknowTemplate
