import React from 'react'
import { Link } from 'gatsby'

import logoDark from '../assets/images/logo-dark.png'


const Footer = ({ props }) => (
  <footer className="footer">
      <div className="container">
        <div className="row gap-y align-items-center">

          <div className="col-md-3 text-center text-md-left">
            <Link to="/" className="nav-link">
            <img className="logo-dark align-bottom" src={logoDark} alt="Arman - The Green B & B" style={{height:"40px"}}/>
            </Link>

          </div>

          <div className="col-md-6">
            <div className="nav nav-center">
              <Link to="/termsandconditions" className="nav-link">Terms</Link>
              <Link to="/policies" className="nav-link">Policies</Link>
              <Link to="/cancellationpolicy" className="nav-link">Cancellations</Link>
              <Link to="/refundpolicy" className="nav-link">Refunds</Link>
            </div>
          </div>

          <div className="col-md-3 text-center text-md-right">
            <small>© 2019. All rights reserved.</small>
          </div>

        </div>
      </div>
        </footer>
)

export default Footer
