import React from 'react'
import { Link } from 'gatsby'
import logoLight from '../assets/images/logo-light.png'
import logoDark from '../assets/images/logo-dark.png'
import {Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem} from 'reactstrap';

class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: true
        };
    }
    toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
      render() {
          return(
            <div>


                <Navbar light={true} expand="md" fixed="dark" data-navbar="fixed" className="navbar navbar-default navbar-fixed-top ">
                    <div className="container">
                        <div className="navbar-left">

                            <NavbarBrand href="/">
                              <img className="logo-light align-bottom" src={logoLight} alt="Arman" style={{height:"70px"}}/>
                            </NavbarBrand>
                        </div>
                        <NavbarToggler onClick={this.toggle} />
                            <Collapse isOpen={!this.state.isOpen} navbar>


                            <ul className="nav nav-navbar">

                            <li className="nav-item">
                                <Link to="/" className="nav-link">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/about" className="nav-link">About</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/facilities" className="nav-link">Facilities</Link>
                            </li>

                              <li className="nav-item">
                                  <Link to="/rooms" className="nav-link">Rooms & Tariff</Link>
                              </li>
                              <li className="nav-item">
                                  <Link to="/gallery" className="nav-link">Gallery</Link>
                              </li>
                              <li className="nav-item">
                                  <Link to="/location" className="nav-link">Location</Link>
                              </li>
                              <li className="nav-item">
                                  <Link to="/contact" className="nav-link">Contact</Link>
                              </li>
                            </ul>
                      </Collapse>

                        <Link className="btn btn-sm btn-round btn-light" to="/booknow">Book</Link>

                    </div>
                  </Navbar>




              </div>
        );
      }
  }

export default Navigation;
